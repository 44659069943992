.Join{
    display: flex;
    padding: 0 2rem;
    gap: 10rem;
}
.left-j{
    color: aliceblue;
    font-size: 3rem;
    font-weight: bold;
    text-transform: uppercase;
    position: relative;
}
.left-j>hr{
    position: absolute;
    width: 10rem;
    border: 2px solid orange;
    border-radius: 20%;
    margin: -15px 0;
}
.left-j>div{
    display: flex;
    gap: 1rem;
}
.right-j{
    display: flex;
   justify-content: center;
   align-items: center;
}
.email-container{
display: flex;
gap: 3rem;
background-color: grey;
padding: 1rem;
}
.email-container>input{
background-color: transparent;
border: none;
outline: none;
color: white;
}
::placeholder{
    color: wheat;
}
.btn-j{
    background-color: orange;
    color: wheat;
}