.footer-container{
    position: relative;
}
.footer-container>hr{
    border: 1px solid grey;
}
.footer{
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
gap: 4rem ;
align-items: center;
justify-content: center;
height: 15rem;
margin-top: -5rem;
}
.social-links{
    display: flex;
    gap: 4rem;
 
}
.social-links>img{
    width: 3rem;
    height: 3rem;
    cursor: pointer;
}
.logo-f>img{
    width: 20rem;
    margin-left: 33rem;
    margin-top: -5rem;
    
}
