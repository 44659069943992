.plans-container{
    margin-top: -4rem;
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    position: relative;
}
.plans{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3rem;
}
.plan{
    display: flex;
   flex-direction: column;
    background-color: orange;
    color: black;
    gap: 2rem;
    padding: 1.5rem;
    width: 15rem;
    padding-bottom: 9rem;
}
.plan:nth-child(2){
    background-color:yellow ;
    transform: scale(1.1);
}
.plan>:nth-child(2){
    font-size: 1rem;
    font-weight: bold;
}
.plan>:nth-child(3){
    font-size: 1rem;
    font-weight: bold;
}

