.hero{
    display: flex;
    justify-content:space-between;
}
.left-h{
padding: 2rem;
padding-top: 1.5rem;
flex: 3;
display: flex;
gap: 2rem;
flex-direction: column;


}
.right-h{
    flex: 1;
    position: relative;
    background-color: orange;
}
.the-best-ad{
    margin-top: 2rem;
    background-color: rgb(0, 174, 255);
    border-radius: 4rem;
    width: fit-content;
    padding: 20px 13px;
    text-transform: uppercase;
    color: wheat;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.the-best-ad>span{
    z-index: 2;
}
.the-best-ad>div{
    position: absolute;
    background-color: orange;
    width: 5.4rem;
    height: 80%;
    left: 8px;
    border-radius: 3rem;
z-index: 1;
}

.hero-text{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    text-transform: uppercase;
    font-size: 4.5rem;
    font-weight: bold;
    color: aliceblue;
    text-transform: inherit;
  margin-right: 10rem;
}
.hero-text>div:nth-of-type(3){
    font-size: 1rem;
    font-weight: normal;
    text-transform: none;
    letter-spacing: 1px;
    width: 80%;
} 
.figures{
    display: flex;
    gap: 2rem;
}
.figures>div{
    display: flex;
    flex-direction: column;
}
.figures>div>span:nth-child(1){
    color: aliceblue;
    font-size: 2rem;
}
.figures>div>span:nth-child(2){
    color: brown;
text-transform: uppercase;
    
}
.hero-buttons{
    display: flex;
    gap: 1rem;
    font-weight: normal;
}
.hero-buttons>:nth-child(1){
    color: wheat;
    background-color: orange;
    width: 8rem;
}
.hero-buttons>:nth-child(2){
    color: wheat;
    background-color: transparent;
    width: 8rem;
    border: 2px solid orange;
}

.right-h>.btn{
    position: absolute;
    right: 1rem;top: 2rem;color: black;
}
.heart-rate{
    display: flex;
    flex-direction: column; 
     gap: 1rem;
    background-color: darkgray;
    width: fit-content;
    padding: 1rem;
    align-items: start;
  border-radius: 5px;
  position: absolute;
  right: 2rem;
  top: 5rem;
}
.heart-rate>:nth-child(2){
   color: darkslategray;
font-weight: bold;
}
.heart-rate>:nth-child(3){
    color: white;
    font-size: 1.5rem;
    
}
.heart-rate>img{
    width: 12rem;
}
.hero-image{
    position: absolute;
    right: 12.5rem;
    top: 10rem;
    width: 20rem;
}
.calories{
    display: flex;
    gap: 2rem;
    background-color: orange;
    border-radius: 5px;
    padding: 1rem;
    width: fit-content;
    position: absolute;
    top: 42rem;
    right: 28rem;
}
.calories>img{
    width: 8rem;
}
.calories>div{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.calories>div>:nth-child(1){
    color: green;
}
.calories>div>:nth-child(2){
    color: black;
    font-size: 1.5rem;
    
}
