.Testimonials{
    display: flex;
    gap: 1rem;
/* padding: 0 2rem; */
padding-bottom: 2rem;
padding-top: 1rem;
}
.left-t{
    display: flex;
    flex: 1 1;
    gap: 2rem;
    flex-direction: column;
    text-transform: uppercase;
    color: wheat;
}
.left-t>:nth-child(1){
    color: orange;
    font-weight: bold;
}
.left-t>:nth-child(2){
 
    font-weight: bold;
    font-size: 3rem;
}
.left-t>:nth-child(3){
  
    font-size: 3rem;
    font-weight: bold;
}
.left-t>:nth-child(4){
  
    text-transform: none;
    text-align: justify;
    letter-spacing: 2px;
    line-height: 40px ;
}
.right-t{
    flex: 1 1;
    position: relative;
}
.right-t>:nth-child(1){
    position: absolute;
    width: 17rem;
    height: 20rem;
    border: 2px solid orange;
    background-color: transparent;
    right: 9rem;
    top: 0.9;
}
.right-t>:nth-child(2){
    position: absolute;
    width: 17rem;
    height: 19rem;
right: 7rem;
    background-color: orange;
    top: 3rem;
}
.right-t>img{
    position: absolute;
    width: 17rem;
    height: 20rem;
    object-fit: cover;
    right: 8rem;
    top: 2rem;
}
.arrows{
    display: flex;
    gap: 1rem;
    position: absolute;
    bottom: 0.1rem; 
    left: 9rem;
}
.arrows>img{
    width: 1.5rem;
    cursor: pointer;
}