.Programs{
   color: aliceblue;
   display: flex;
   flex-direction: column;
   gap: 2rem;
   padding: 0 2rem;
}
.programs-header{
    display: flex;
    gap: 5rem;
    font-weight: bold;
    font-size: 3rem;
    color: wheat;
    text-transform: uppercase;
    font-style: italic;
    justify-content: space-around;
    margin-top: -5rem;
}
.programs-categories{
    display: flex;
    gap: 1rem;
}
.category{
    display: flex;
    flex-direction: column;
    background-color: lime;
    padding: 2rem;
    color: black;
    gap: 1rem;
    justify-content:space-between;
}
.category>:nth-child(1){
   width: 2rem;
   height: 2rem;
   fill: white; 
}
.category>:nth-child(2){
   font-size: 1rem;
   font-weight: bold;
 }.category>:nth-child(3){
    font-size: 0.9rem;
    line-height: 25px;
  }
  .category:hover{
    cursor: pointer;
    background-color: orange;
  }